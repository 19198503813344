:root {
  --white-color: #f6f6f6;
  --Nav-height: 5rem;
  --padding-nav: 1.8em;
  --nav-spacer-right: 3.5rem;
  --nav-item-width: 3.9rem;
  --home-img-width: 17rem;
  --typewritter-speed: 6s;
  --blink-speed: 750ms;
  --animation-speed: 3s;
  --card-img-width: 13rem;
  --card-img-height: 20rem;
  --add-width: 4rem;
  --add-height: 1.5rem;
  --cart-counter-size: 1rem;
  --cart-size: 1.8rem;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

*:focus {
  outline-style: none;
}

html,
body,
.App {
  width: 100%;
  height: 100%;
  background-color: var(--white-color);
}

/* Nav */

.Nav {
  width: 100%;
  height: var(--Nav-height);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Inter";
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25);
  background-color: var(--white-color);
  padding: 0px var(--padding-nav);
  opacity: 0;
  transform: translateY(-3rem);
  animation: fadeInVertical var(--animation-speed) ease forwards;
}

.logo {
  width: 50%;
  font-size: 2rem;
  font-family: "Uncial Antiqua";
  font-weight: normal;
}

.items-container {
  width: 50%;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}

.nav-item-spacer {
  position: absolute;
  height: 100%;
  width: 1px;
  right: var(--nav-spacer-right);
  background-color: black;
}

.nav-item-one,
.nav-item-two {
  width: var(--nav-item-width);
  display: flex;
  align-items: center;
  font-size: 1.2rem;
}

.nav-item-two {
  justify-content: flex-end;
}

a,
a:visited {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

/* Main */

.Main {
  width: 100%;
  height: 100%;
  background-color: var(--white-color);
}

.part-one {
  width: 100%;
  min-height: calc(100vh - var(--Nav-height));
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0px var(--padding-nav);
  gap: 3em;
}

.home-images {
  width: var(--home-img-width);
  box-shadow: -7px 7px 6px rgba(0, 0, 0, 0.5);
  opacity: 0;
  margin: 2em 0em;
  transform: translateX(-5rem);
  animation: fadeInSides var(--animation-speed) ease forwards;
}

.home-text-container {
  width: 340px;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px solid black;
  opacity: 0;
  transform: translateY(3rem);
  animation: fadeInVertical var(--animation-speed) ease forwards;
  margin-bottom: 5em;
}

.box-text-1 {
  font-size: 1rem;
  font-family: "Source Code Pro", monospace;
  position: relative;
}

.box-text-1::before,
.box-text-1::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.box-text-1::before {
  background-color: var(--white-color);
  animation: typewritter var(--typewritter-speed) steps(33)
    var(--animation-speed) forwards;
}

.box-text-1::after {
  width: 0.125em;
  background: black;
  animation: typewritter var(--typewritter-speed) steps(33)
      var(--animation-speed) forwards,
    blink var(--blink-speed) steps(33) infinite;
}

/* Shop */

.Shop {
  width: 100%;
  min-height: calc(100vh - var(--Nav-height));
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  font-family: "Inter", sans-serif;
}

/* search Area */

.search-cart {
  width: 100%;
  min-height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 1.5em;
  opacity: 0;
  transform: translateX(5rem);
  animation: fadeInSides var(--animation-speed) ease forwards;
  background-color: var(--white-color);
}

.search-bar {
  width: 13.5rem;
  height: 1.5rem;
  border: 1px solid black;
  border-radius: 10rem;
  padding: 0.4em;
  font-size: 0.9rem;
  background-color: transparent;
}

.cart-btn {
  width: var(--cart-size);
  height: var(--cart-size);
  position: absolute;
  right: var(--padding-nav);
  padding: 0px;
  background-color: transparent;
  border: 0px solid transparent;
}

.cart-counter {
  width: var(--cart-counter-size);
  height: var(--cart-counter-size);
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0px solid transparent;
  border-radius: 50%;
  background-color: black;
  position: absolute;
  right: calc(var(--cart-size) - 13px);
  bottom: -0.3rem;
}

.cart-counter-text {
  font-size: 0.7em;
  margin: 0;
  color: white;
}

.cart-icon {
  width: 100%;
}

/* cards */

.items {
  width: auto;
  height: auto;
  display: grid;
  justify-items: center;
  align-content: center;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-row: auto;
  margin: 1.5em 0px;
  gap: 2em;
}

.Card {
  width: auto;
  height: auto;
  position: relative;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  transition: 250ms ease-in-out;
  opacity: 0;
  transform: translateY(3rem);
  animation: fadeInVertical var(--animation-speed) ease forwards;
}

.Hide {
  display: none;
}

.title-price {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.title,
.price {
  margin: 1em 0px;
  font-size: 0.9rem;
}

.card-img {
  width: var(--card-img-width);
  height: var(--card-img-height);
  object-fit: cover;
  transition: 250ms;
  box-shadow: -7px 7px 6px rgba(0, 0, 0, 0.5);
}

.add-btn {
  width: var(--add-width);
  height: var(--add-height);
  border: 0px solid transparent;
  border-radius: 1rem;
  background-color: black;
  color: white;
  font-size: 0.9em;
  position: absolute;
  left: calc((var(--card-img-width) / 2) - (var(--add-width) / 2));
  bottom: 0.6rem;
  cursor: pointer;
}

@keyframes typewritter {
  to {
    left: 100%;
  }
}

@keyframes blink {
  to {
    background: transparent;
  }
}

@keyframes fadeInSides {
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInVertical {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Cart */

.Cart {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-family: "Inter", sans-serif;
  padding: 0px var(--padding-nav);
  background-color: var(--white-color);
}

.cart-title {
  align-self: flex-start;
  font-weight: 500;
  margin: 2em 0px;
}

.all-items {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 2em 0px;
  gap: 1.5em;
}

/* Item */

.Item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  gap: 0.8em;
}

.item-title-price {
  width: 12rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  gap: 1em;
  font-size: 1rem;
}

.counter-clear {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  gap: 1em;
}

.counter {
  width: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.btn-add,
.btn-subtract,
.input-counter {
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1rem;
  background-color: black;
  color: white;
  border: 0px solid transparent;
  transition: ease-in-out 190ms;
}

.btn-add {
  border-radius: 0 1rem 1rem 0;
}

.btn-subtract {
  border-radius: 1rem 0 0 1rem;
}

.btn-add:active,
.btn-subtract:active {
  background-color: var(--white-color);
  color: black;
  border: 2px solid black;
}

.trash-icon {
  width: 1rem;
}

/* purchase section */

.purchase-area {
  width: 100%;
  height: 9rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  gap: 2em;
  padding: 0px var(--padding-nav);
}

.total-display {
  width: 100%;
  height: 2rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.title,
.price {
  font-size: 1rem;
}

.text-total,
.total-price {
  margin: 0.5em 0em;
  margin-right: 0.5em;
  font-size: 1.1rem;
}

.purchase-area > a,
.purchase-area > a:visited {
  color: white;
}

.btn-checkout {
  width: 8rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.1rem;
  background-color: black;
  border: 0px solid transparent;
  border-radius: 1rem;
}

@media only screen and (width > 750px) and (height < 1000px) {
  :root {
    --padding-nav: 2em;
    --home-img-width: 12rem;
  }

  .part-one {
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 4rem;
  }

  .home-text-container {
    margin-bottom: 0em;
  }

  .search-bar {
    width: 18rem;
  }

  .items {
    grid-template-columns: 1fr 1fr;
    margin: 2em 0px;
    gap: 3em;
  }
}

@media only screen and (width > 750px) and (height < 1000px) {
  :root {
    --padding-nav: 4.5em;
  }

  .items {
    gap: 4em;
  }
}

/* tablet */

@media only screen and (width > 550px) and (height > 1000px) {
  :root {
    --padding-nav: 5em;
    --Nav-height: 6rem;
    --home-img-width: 28rem;
    --nav-spacer-right: 4rem;
    --nav-item-width: 4.3rem;
  }

  .logo {
    font-size: 2.5rem;
  }

  .nav-item-one,
  .nav-item-two {
    font-size: 1.3rem;
  }

  .part-one {
    justify-content: center;
    flex-direction: column;
    gap: 4rem;
  }

  .home-images {
    margin: 0;
  }

  .home-text-container {
    width: 25rem;
    height: 6rem;
    margin-top: 5em;
  }

  .box-text-1 {
    font-size: 1.1rem;
  }

  .search-bar {
    width: 18rem;
  }

  .items {
    grid-template-columns: 1fr 1fr;
    margin: 2em 0px;
    gap: 3rem;
    column-gap: 5rem;
  }

  .item-title-price {
    width: 14rem;
  }

  .counter {
    width: 6rem;
  }

  .btn-add,
  .btn-subtract,
  .input-counter {
    width: 1.7rem;
    height: 1.7rem;
    font-size: 1.1rem;
  }

  .text-total,
  .total-price {
    font-size: 1.2rem;
  }

  .btn-checkout {
    width: 9rem;
    height: 3rem;
    font-size: 1.3rem;
  }
}

@media only screen and (width > 1000px) and (height > 550px) {
  :root {
    --padding-nav: 6em;
    --Nav-height: 6rem;
    --home-img-width: 20rem;
    --nav-spacer-right: 4rem;
    --nav-item-width: 4.3rem;
  }

  .logo {
    font-size: 2.5rem;
  }

  .nav-item-one,
  .nav-item-two {
    font-size: 1.3rem;
  }

  .part-one {
    justify-content: center;
    flex-direction: row;
  }

  .home-images {
    margin: 4em 0;
  }

  .home-text-container {
    width: 25rem;
    height: 6rem;
  }

  .box-text-1 {
    font-size: 1.1rem;
  }

  .search-bar {
    width: 25rem;
    height: 1.7rem;
    font-size: 1.1rem;
  }

  .trash-icon {
    width: 1.2rem;
  }

  .items {
    grid-template-columns: 1fr 1fr;
    margin: 2em 0px;
    gap: 4em;
  }

  .item-title-price {
    width: 14rem;
  }

  .counter {
    width: 6rem;
  }

  .btn-add,
  .btn-subtract,
  .input-counter {
    width: 1.7rem;
    height: 1.7rem;
    font-size: 1.1rem;
  }

  .text-total,
  .total-price {
    font-size: 1.2rem;
  }

  .btn-checkout {
    width: 9rem;
    height: 3rem;
    font-size: 1.3rem;
  }
}

/* desktop */

@media only screen and (width > 1000px) and (height > 1000px) {
  :root {
    --padding-nav: 8em;
    --Nav-height: 6rem;
    --home-img-width: 28rem;
    --nav-spacer-right: 4rem;
    --nav-item-width: 4.3rem;
  }

  .logo {
    font-size: 2.5rem;
  }

  .nav-item-one,
  .nav-item-two {
    font-size: 1.3rem;
  }

  .part-one {
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .home-images {
    margin: 0;
    align-self: center;
  }

  .home-text-container {
    width: 32rem;
    height: 7rem;
    margin-top: 5em;
  }

  .box-text-1 {
    font-size: 1.3rem;
  }

  .search-bar {
    width: 20rem;
  }

  .items {
    grid-template-columns: 1fr 1fr 1fr;
    margin: 2em 0px;
    gap: 4rem;
    column-gap: 6rem;
  }

  .item-title-price {
    width: 14rem;
  }

  .counter {
    width: 6rem;
  }

  .btn-add,
  .btn-subtract,
  .input-counter {
    width: 1.7rem;
    height: 1.7rem;
    font-size: 1.1rem;
  }

  .text-total,
  .total-price {
    font-size: 1.2rem;
  }

  .btn-checkout {
    width: 9rem;
    height: 3rem;
    font-size: 1.3rem;
  }
}

@media only screen and (width > 1200px) and (height > 1000px) {
  :root {
    --padding-nav: 18em;
    --Nav-height: 6rem;
    --home-img-width: 28rem;
    --nav-spacer-right: 4rem;
    --nav-item-width: 4.3rem;
  }

  .logo {
    font-size: 2.5rem;
  }

  .nav-item-one,
  .nav-item-two {
    font-size: 1.3rem;
  }

  .part-one {
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}
